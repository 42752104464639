@import '../../../../styles/variables';

.skeletonWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.labelLoading {
  height: 18px;
  margin-bottom: 5px;
  border-radius: 5px;

  @media (min-width: $tablet) {
    height: 20px;
  }
}

.link {
  text-decoration: none;

  &:hover,
  &:focus-visible {
    .title,
    .subtitle {
      color: $echoBeach;
    }
  }
}

.title {
  color: $textPrimary;
}

.subtitle {
  color: $textSecondary;
  font-style: italic;
}
