@import '../../../../styles/variables';

.paywall {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  font-size: 50px;
  background-color: $paywall-background;
  padding: 20px;

  display: flex;
  align-items: center;
}

.textWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (min-width: $desktopSm) {
    justify-content: left;
  }
}

.callToActionButton {
  flex-shrink: 0;
}

.titleText {
  margin-right: 25px;
}

.subText {
  padding-right: 10px;
}

.showLargePaywallDesktop .textWrap {
  @media (min-width: $desktopSm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.showLargePaywallDesktop.paywall {
  @media (min-width: $desktopSm) {
    padding-left: 50px;
  }
}

.showLargePaywallDesktop .titleText {
  @media (min-width: $desktopSm) {
    margin-bottom: 19px;
  }
}

.showLargePaywallDesktop .subText {
  @media (min-width: $desktopSm) {
    margin-bottom: 23px;
  }
}
