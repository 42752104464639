@import '../../../../styles/variables';

.dayHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.date {
  color: $textPrimary;
}

.day {
  color: $textSecondary;
  margin-bottom: 4px;
}

.dayToWatch {
  color: $textPrimary;
}

.loading {
  height: 40px;
}

.star {
  height: 16px;
  margin-bottom: 4px;
  width: 16px;

  svg {
    path {
      fill: $brandSecondary;
    }
  }
}
