@import '../../styles/variables';

$forecast-columns-max-screen: 8;
$forecast-columns-small-desktop-screen: 8;
$forecast-columns-tablet-screen: 4;
$forecast-columns-large-mobile-screen: 3;
$forecast-columns-mobile-screen: 3;
$forecast-columns-small-mobile-screen: 2;

$metric-forecast-columns-max-screen: 6;
$metric-forecast-columns-small-desktop-screen: 5;
$metric-forecast-columns-tablet-screen: 4;
$metric-forecast-columns-large-mobile-screen: 3;
$metric-forecast-columns-mobile-screen: 2;
$metric-forecast-columns-small-mobile-screen: 2;

@mixin row-spacing {
  margin-top: 5px;
  margin-bottom: 5px;
}

@mixin row-panel-column-end-breakpoints {
  grid-column-end: calc($forecast-columns-small-mobile-screen + var(--sidebar-columns) + 1);

  @media (min-width: $small-mobile) {
    grid-column-end: calc($forecast-columns-mobile-screen + var(--sidebar-columns) + 1);
  }

  @media (min-width: $large-mobile) {
    grid-column-end: calc($forecast-columns-large-mobile-screen + var(--sidebar-columns) + 1);
  }

  @media (min-width: $tablet) {
    grid-column-end: calc($forecast-columns-tablet-screen + var(--sidebar-columns) + 1);
  }

  @media (min-width: $desktopSm) {
    grid-column-end: calc($forecast-columns-small-desktop-screen + var(--sidebar-columns) + 1);
  }

  @media (min-width: $desktop) {
    grid-column-end: calc($forecast-columns-max-screen + var(--sidebar-columns) + 1);
  }

  &.metricUnits {
    grid-column-end: calc($metric-forecast-columns-small-mobile-screen + var(--sidebar-columns) + 1);

    @media (min-width: $small-mobile) {
      grid-column-end: calc($metric-forecast-columns-mobile-screen + var(--sidebar-columns) + 1);
    }

    @media (min-width: $large-mobile) {
      grid-column-end: calc($metric-forecast-columns-large-mobile-screen + var(--sidebar-columns) + 1);
    }

    @media (min-width: $tablet) {
      grid-column-end: calc($metric-forecast-columns-tablet-screen + var(--sidebar-columns) + 1);
    }

    @media (min-width: $desktopSm) {
      grid-column-end: calc($metric-forecast-columns-small-desktop-screen + var(--sidebar-columns) + 1);
    }

    @media (min-width: $desktop) {
      grid-column-end: calc($metric-forecast-columns-max-screen + var(--sidebar-columns) + 1);
    }
  }

}

.forecastDayTable {
  display: block;
}

.container {
  width: 100%;
}

.tableContainer {
  display: grid;
  grid-template-columns: 40% repeat(var(--number-of-columns), calc(60% / $forecast-columns-small-mobile-screen));

  @media (min-width: $small-mobile) {
    grid-template-columns: 40% repeat(var(--number-of-columns), calc(60% / $forecast-columns-mobile-screen));
  }

  @media (min-width: $large-mobile) {
    grid-template-columns: 40% repeat(var(--number-of-columns), calc(60% / $forecast-columns-large-mobile-screen));
  }

  @media (min-width: $tablet) {
    grid-template-columns: 25% repeat(var(--number-of-columns), calc(75% / $forecast-columns-tablet-screen));
  }

  @media (min-width: $desktopSm) {
    grid-template-columns: 20% repeat(var(--number-of-columns), calc(80% / $forecast-columns-small-desktop-screen));
  }

  @media (min-width: $desktop) {
    grid-template-columns: 20% repeat(var(--number-of-columns), calc(80% / $forecast-columns-max-screen));
  }

  &.metricUnits {
    grid-template-columns: 40% repeat(var(--number-of-columns), calc(60% / $forecast-columns-small-mobile-screen));

    @media (min-width: $small-mobile) {
      grid-template-columns: 40% repeat(var(--number-of-columns), calc(60% / $metric-forecast-columns-mobile-screen));
    }

    @media (min-width: $large-mobile) {
      grid-template-columns: 40% repeat(var(--number-of-columns), calc(60% / $metric-forecast-columns-large-mobile-screen));
    }

    @media (min-width: $tablet) {
      grid-template-columns: 25% repeat(var(--number-of-columns), calc(75% / $metric-forecast-columns-tablet-screen));
    }

    @media (min-width: $desktopSm) {
      grid-template-columns: 20% repeat(var(--number-of-columns), calc(80% / $metric-forecast-columns-small-desktop-screen));
    }

    @media (min-width: $desktop) {
      grid-template-columns: 20% repeat(var(--number-of-columns), calc(80% / $metric-forecast-columns-max-screen));
    }
  }

  overflow-x: visible;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.paywall {
  position: sticky;
  left: 0;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 2;
  z-index: 4;

  @include row-panel-column-end-breakpoints;
  @include row-spacing;

  @media (min-width: $desktopSm) {
    position: sticky;
    left: 20%;

    grid-column-start: calc(var(--sidebar-columns) + 1 + var(--paywall-days));
    grid-row-start: calc(var(--heading-rows) + 1);
    grid-row-end: calc(var(--number-of-forecasts) + var(--heading-rows) + 1);
    grid-column-end: calc($forecast-columns-small-desktop-screen + var(--sidebar-columns) + 1 + var(--paywall-days));

    &.metricUnits {
      grid-column-end: calc($metric-forecast-columns-small-desktop-screen + var(--sidebar-columns) + 1 + var(--paywall-days));
    }
  }

  @media (min-width: $desktop) {
    grid-column-end: calc($forecast-columns-max-screen + var(--sidebar-columns) + 1 + var(--paywall-days));

    &.metricUnits {
      grid-column-end: calc($metric-forecast-columns-max-screen + var(--sidebar-columns) + 1 + var(--paywall-days));
    }
  }
}

.rowPanel {
  grid-column-start: 1;
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 2px 10px 2px $product-extra-light;
  border-radius: 10px;
  @include row-spacing;
  @include row-panel-column-end-breakpoints;
}

.headerDateRowPanel {
  grid-column-start: 2;
  grid-row: 1;
  border: solid;
  z-index: 7;
  position: sticky;
  top: 0;
  left: 40%;
  border-radius: 8px;
  border: 1px solid $product-light;
  @include row-spacing;

  @media (min-width: $small-mobile) {
    left: 40%;
  }

  @media (min-width: $large-mobile) {
    left: 0;
    grid-column-start: 1;
    left: 0;
    border: none;
  }

  @include row-panel-column-end-breakpoints;
}

.spotLabel {
  display: flex;
  grid-column-start: 1;
  grid-column-end: 1;
  position: sticky;
  left: 0;
  background: $product-base;
  z-index: 5;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 18px;
  border-radius: 10px 0px 0px 10px;
  @include row-spacing;

  & > h6 {
    align-self: center;
  }

  box-shadow: 0 4px 2px -2px $product-extra-light;
  align-items: center;
  cursor: pointer;

  h4 {
    font-weight: 600;
    text-align: left;
  }
}

.surfCurveChartContainer {
  z-index: 2;
  @include row-spacing;
}

.dayHeadingWrapper {
  grid-row: 1;
  height: 76px;
  padding-bottom: 8px;
  padding-top: 8px;
  position: sticky;
  scroll-snap-align: end;
  text-align: center;
  top: 0;
  z-index: 9;

  @include row-spacing;
}

.day {
  width: 100%;
  text-align: center;
  background-color: transparent;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
  @include row-spacing;
}

.spotRowLoadingWrapper, .spotRowErrorWrapper {
  @include row-panel-column-end-breakpoints;
  @include row-spacing;

  z-index: 2;

  grid-column-start: 1;
  position: sticky;
  left: 0;
  width: 100%;
  min-height: 75px;
  border-radius: 10px;

  @media (min-width: $tablet) {
    min-height: 90px;
  }
}

.spotRowLoading {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.spotErrorWrapper {
  border-radius: 10px;
  background-color: $product-extra-light;
  width: 100%;
  height: 100%;
}
