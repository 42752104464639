@import '../../styles/variables';

.forecasterContentNow {
  background-color: $background;
  position: relative;
  z-index: 1;
}

.paywallLockButton {
  background-color: $brandSecondary;
  height: 44px;
  width: 44px;

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: $white;
    }
  }

  &:hover,
  &:focus-visible {
    background-color: $textPrimary;
  }
}

.paywallLockButtonUnlocked {
  svg {
    path {
      fill: $greenFlash;
    }
  }
}

.loadingNow {
  background-color: $background;
  border-radius: 8px;
  display: block;
  min-height: 160px;
}

.star {
  margin-bottom: -1px;
}

.forecastNotes {
  a {
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
}
