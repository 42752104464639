@import '../../styles/variables';
@import '../../styles/mixins';

.missingFavouritesContent {
  margin-bottom: 50px;
}

.textBackground {
  background-color: $product-extra-light;
  border-radius: 20px;
  width: 100%;
  height: 372px;
  position: relative;
  overflow-x: auto;
}

.title {
  padding-bottom: 32px;
  padding-top: 47px;
  color: $product-secondary;
}

.textBox {
  width: 270px;
  margin: auto;
  text-align: center;
  line-height: 24px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .boldText {
    color: $product-secondary;
  }
  .normalText {
    margin-top: 9px;
    color: $product-body-secondary;
  }
  .favoritesError {
    flex-direction: row;
  }
}

.button {
  position: relative;
  margin: 35px auto 0 auto;
  &:hover {
    cursor: pointer;
    circle {
      stroke: $product-body-secondary;
    }
    path {
      fill: $product-body-secondary;
    }
  }
}
