@import '../../styles/variables';

.observation {
  align-items: flex-end;
  color: $gray10;
  display: flex;
  margin-bottom: 3px;
  width: 100%;
}

.value {
  line-height: 24px;
}

.unit {
  line-height: 24px;
  text-transform: lowercase;
}

.observationSuffix {
  line-height: 24px;
  margin-left: 6px;
}

.description {
  color: $gray10;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 18px;
}

.observedBy {
  align-items: center;
  color: $gray30;
  display: flex;
  width: 100%;
  white-space: nowrap;
}

.smartCamIcon {
  height: 16px;
  width: 16px;

  rect:first-child {
    fill: transparent;
  }

  path,
  rect {
    fill: $gray30;
  }
}

.forecasterIcon {
  border-radius: 100%;
  height: 12px;
  overflow: hidden;
  position: relative;
  width: 12px;
}

.observedByValue {
  font-size: 10px;
  margin-left: 4px;
}

.observedAt {
  font-size: 10px;
}

.separator {
  font-size: 10px;
  line-height: 1em;
  margin: 0 4px;
}
