@import '../../styles/variables';

.expandButton {
  height: 32px;
  width: 32px;

  &:hover,
  &:focus-visible {
    background-color: $gray40;
  }
}
