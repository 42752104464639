@import '../../../../styles/variables';

$hover-background: #00000006;
$hover-background-main: #00000016;
$focus-border: $product-light solid 1px;

.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 80px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  color: inherit;
  padding-top: 15px;
  padding-bottom: 15px;
}

.wrapperActiveHover {
  &:hover {
    border-radius: 8px;
    background: $hover-background-main;
  }
}

.activeText {
  color: $product-body;
  margin-bottom: 5px;
}

.inactiveText {
  color: $inactive-color;
  margin-bottom: 5px;
}

.details {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-bottom: 10px;

  h5 {
    font-weight: 700;
  }

  @media (min-width: $large-mobile) {
    margin-bottom: 33px;
  }
}

.customForecastDetails {
  height: auto;
  margin-bottom: 0;
}

.units {
  font-size: 14px;
  display: none;

  @media (min-width: $large-mobile) {
    display: inline;
  }
}

.conditions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  max-width: 32px;

  @media (min-width: $tablet) {
    max-width: 68px;
  }
}

.bar {
  height: 4px;
  border-radius: 4px;

  width: 10px;

  @media (min-width: $tablet) {
    width: 22px;
  }
}

.dynamicClassHover {
  background: $hover-background;
}

.dynamicClassFocus {
  box-sizing: border-box;
  border: $focus-border;
}

.bar--paywall {
  background-color: $paywall-background;
}

.heightLabelCustomForecast {
  display: block;

  .units {
    display: inline;
  }

  @media (min-width: $large-mobile) {
    display: inline;
  }
}

.nodata {
  visibility: hidden;
}
