@import '../../styles/variables';

.favoritesHeader {
  border-bottom: color('gray', 30) solid 1px;
  padding: 30px 0 0;

  h1 {
    color: $brandSecondary;
  }
}

.headerWrap {
  margin: 10px 0 25px;
}

.headerWrapForecastContent {
  svg {
    height: 32px;
    width: 32px;
  }
}

.favoritesTabs {
  padding-bottom: 40px;
}

.tab {
  font-weight: 700;
  margin-right: 40px;
  min-width: 0px;
  padding: 12px 0;

  &:hover,
  &:focus-visible {
    color: $brandSecondary;
  }
}

.editButton {
  color: $textPrimary;
  font-weight: 700;
  padding: 0 20px;

  @media (min-width: $tablet) {
    font-weight: 600;
  }

  .editIcon {
    margin-right: 12px;
  }

  .editingIcon {
    height: 12px;
    margin-right: 12px;
    width: 12px;
  }
}

.headerButtons.isEditingFavorites {
  button {
    color: $grayLight;
  }

  path {
    fill: $grayLight;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 8px;
  width: 100%;
}

.forecasterContent {
  margin: 16px 0;
}
