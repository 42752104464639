@import '../../styles/_variables.scss';

.forecasterProfile {
  text-decoration: none;
}

.profileImage {
  border-radius: 100%;
  border-style: solid;
  border-width: 3px;
  height: 40px;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.noCondition {
  border-color: transparent;
}

.titleSuffix {
  margin-left: 5px;
}
