// .linkList
// .linkListAlignCenter
// .linkListLoader

@import '../../styles/variables.scss';

$in-page-vertical-offset: 130px;

@keyframes fadeInCard {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  background-color: $brandSecondary;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  @media (min-width: $tablet) {
    $vertical-offset: $site-header-height-tablet + $site-footer-height-tablet + $in-page-vertical-offset;
    min-height: calc(100vh - $vertical-offset);
  }

  @media (min-width: $tabletLg) {
    $vertical-offset: $site-header-height-desktop + $site-footer-height-medium + $in-page-vertical-offset;
    min-height: calc(100vh - $vertical-offset);
  }

  @media (min-width: $desktop) {
    $vertical-offset: $site-header-height-desktop + $site-footer-height-desktop + $in-page-vertical-offset;
    min-height: calc(100vh - $vertical-offset);
  }
}

.isNative {
  @media (min-width: $tabletLg) {
    min-height: 100vh;
  }

  @media (min-width: $tablet) {
    min-height: 100vh;
  }

  @media (min-width: $desktop) {
    min-height: 100vh;
  }
}

.content {
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
}

.header {
  margin: 0 auto;
  max-width: 760px;
  padding-top: 40px;
  text-align: center;

  a {
    color: $background;
    text-decoration: none;
    transition: color 100ms ease-in-out;

    img {
      border: solid 1px transparent;
      transition: border 100ms ease-in-out;
    }

    &:hover,
    &:focus-visible {
      color: $divider;
      text-decoration: none;

      img {
        border: solid 1px $divider;
      }
    }
  }
}

.star {
  margin-bottom: -1px;

  path {
    fill: $background;
  }
}

.emptyCard {
  animation: fadeInCard 200ms ease-in-out;
  animation-delay: 250ms;
  animation-fill-mode: backwards;
  background: rgba(250, 250, 250, 0.08);
  border-radius: 8px;
  margin: 0 auto 16px;
  max-width: 760px;
  min-height: 95px;
  text-align: center;
}

.emptyCardTextHeadline {
  color: $background;
  margin-bottom: 8px;
}

.emptyCardText {
  color: $background;
  margin: 0;
}

.scrollXWrapper {
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  margin-left: -2%;
  margin-right: auto;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none;
  padding-left: 2%;
  scrollbar-width: none;
  white-space: nowrap;
  width: 102%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.linkList {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  text-align: left;
  white-space: nowrap;

  &::after {
    content: '';
    display: inline-block;
    width: 10%;
  }

  :global {
    .MuiButtonBase-root {
      background: rgba(255, 255, 255, 0.08);
      color: $background;

      &:hover,
      &:focus-visible {
        background: rgba(255, 255, 255, 0.2);
      }

      &.Mui-disabled {
        background-color: $background;
        color: $brandSecondary;
      }
    }
  }

  li {
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 0;
  }
}

.card {
  animation: fadeInCard 200ms ease-in-out;
  animation-delay: 250ms;
  animation-fill-mode: backwards;
}

.cardTextHeadline {
  color: $background;
}

.cardText {
  color: $background;
  line-height: 1.3em;
  margin: 0.3em 0 1.3em;
}

.highlightsDateChip {
  background-color: $background;
  border-radius: 4px;
  font-weight: 700;
  margin-bottom: 10px;
  width: 76px;

  :global {
    .MuiChip-label {
      font-size: 12px;
    }
  }
}

.paywallLockButton {
  background-color: transparent;
  height: 32px;
  width: 32px;

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: $white;
    }
  }

  &:hover,
  &:focus-visible {
    background-color: $textPrimary;
  }
}

.paywallLockButtonUnlocked {
  svg {
    path {
      fill: $greenFlash;
    }
  }
}
