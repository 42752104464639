@import '../../styles/variables.scss';

.card {
  background-color: $paywall-background;
  margin: 0 auto;
  max-width: 760px;
  text-align: center;
}

.paywallLogo {
  width: 18px;

  path {
    fill: $brandSecondary;
  }
}

.upgradeButton {
  margin: 0 auto;
  max-width: 360px;
  width: 100%;
}

.playButton {
  background-color: $brandSecondary;
  border-radius: 11px;
  height: 106px;
  margin: 40px auto 10px;
  width: 206px;
}

.dialogCtaWrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  .dialogCtaButton {
    max-width: 366px;
    color: $bronzing;
    width: 100%;
  }
}


