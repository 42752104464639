@import '../../styles/variables';

.alertBox {
    align-items: center;
    background-color: $background;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    padding: 40px;
    position: relative;
}

.paywall {
  background-color: $paywall-background;
}

.forecastDayTableWrapper {
  $in-page-vertical-offset: 267px;

  @media (min-width: $tablet) {
    $vertical-offset: $site-header-height-tablet + $site-footer-height-tablet + $in-page-vertical-offset;
    min-height: calc(100vh - $vertical-offset);
  }

  @media (min-width: $tabletLg) {
    $vertical-offset: $site-header-height-desktop + $site-footer-height-medium + $in-page-vertical-offset;
    min-height: calc(100vh - $vertical-offset);
  }

  @media (min-width: $desktop) {
    $vertical-offset: $site-header-height-desktop + $site-footer-height-desktop + $in-page-vertical-offset;
    min-height: calc(100vh - $vertical-offset);
  }
}
