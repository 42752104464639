@import '../../styles/variables';

.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding-bottom: 36px;
}

.container {
  width: 100%;
  max-width: 510px;
}

.item {
  touch-action: none;
  margin-bottom: 25px;
  padding: 0 12px;
  border-radius: 10px;
  cursor: move;
  border: 1px solid $product-base; // Match the background color as the border should not be seen unless dragging is occurring
  &.saving {
    opacity: 0.5;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spotName {
  font-size: 16px;
  line-height: 22px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove {
  margin-right: 8px;
}

.menuIcon {
  :hover, :focus-visible {
    fill: $product-body-secondary;
  }
}

.isDragging {
  border: 1px solid $product-body;
  opacity: 0.2;
}

.draggingOverAbove {
  border-top: 1px solid $product-body-secondary;
  border-radius: 0px;
}

.draggingOverBelow {
  border-bottom: 1px solid $product-body-secondary;
  border-radius: 0px;
}

.snackbarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.snackbarContent {
  display: flex;
  align-items: center;
  background-color: $product-secondary;
  border-radius: 10px;
  padding: 24px 24px 24px 20px;
  p {
    color: $product-extra-light;
    margin-right: 60px;
  }
}

.saving {
  p {
    margin-right: 0;
    padding: 0 20px;
  }
}

.tickIcon {
  width: 30px;
  height: 30px;
  path {
    fill: $product-extra-light;
  }
}
