@import '../../styles/_variables.scss';

.tooltip {
  display: inline;

  button {
    &:hover,
    &:focus-visible {
      svg {
        filter: invert(0.5) hue-rotate(200deg);
      }
    }
  }
}

.dialog {
  margin: auto;
  z-index: 99999;
}

.dialogPaper {
  height: calc(100vh - 40px);
  margin-top: 40px;
  width: 100%;

  @media (min-width: $tablet) {
    height: auto;
    margin-top: 0;
    max-height: 80%;
    max-width: 435px;
    position: absolute;
    top: 10%;
  }
}

.dialogCloseWrapper {
  height: 44px;
  padding-bottom: 10px;
}

.dialogClose {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;

  @media (min-width: $large-mobile) {
    right: 15px;
    top: 15px;
  }
}

.dialogContent {
  padding-left: 15px;
  max-height: 80vh;
}

.dialogContentText {
  padding-bottom: 10px;
}

.dialogSmall {
  .dialogPaper {
    max-height: 300px;
  }
}

.dialogCinematic {
  :global {
    .MuiDialog-container {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }

  .title {
    color: $white;
  }

  .body {
    p {
      color: $white;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .dialogPaper {
    background-color: transparent;

    @media (min-width: $tablet) {
      max-width: 820px;
    }
  }

  .dialogClose {
    svg {
      path {
        fill: $background;
      }

      circle {
        fill: transparent;
      }
    }

    &:hover,
    &:focus-visible {
      svg {
        path {
          fill: $textPrimary;
        }

        circle {
          fill: $background;
        }
      }
    }
  }

  &.dialogNative {
    :global {
      .MuiDialogTitle-root {
        .MuiIconButton-root {
          display: none;
        }
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.unorderedList {
  display: flex;
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
}

.unorderedList::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

.listItems {
  list-style-type: none;
  padding: 0 5px;
  white-space: nowrap;
}

.button {
  border: 1px solid $product-light !important;
  color: $product-body-secondary !important;

  &:hover,
  &:focus-visible {
    background-color: $white !important;
    color: $product-body !important;
  }
}

.buttonActive {
  background-color: $white !important;
  border-color: 1px solid $product-body-secondary !important;
  color: $product-body !important;
}

.image {
  height: auto;
  width: 100%;
}

.errorMessageContainer {
  min-height: 290px;
  min-width: 340px;
}

.errorMessage {
  height: 350px;
}

.title {
  padding-bottom: 15px;
}

.video {
  outline: none;
  width: 100%;
}
