@import '../../styles/variables';

@keyframes currentWaveRating-barPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.container {
  display: flex;
  align-items: center;
}

.animate {
  .bar {
    animation: currentWaveRating-barPulse 2s ease-in-out;

    // Stagger the animation for each bar
    &:nth-child(1) {
      animation-delay: 1s;
    }
    &:nth-child(2) {
      animation-delay: 1.1s;
    }
    &:nth-child(3) {
      animation-delay: 1.3s;
    }
    &:nth-child(4) {
      animation-delay: 1.5s;
    }
    &:nth-child(5) {
      animation-delay: 1.7s;
    }
  }
}
.rating {
  margin-right: 8px;
  text-transform: uppercase;
}

.bars {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.verticalBars {
  flex-direction: column-reverse;
  gap: 1px;
}

.bar {
  background-color: $grayLight;
  border-radius: 1px;
  height: 4px;
  width: 16px;
}

.verticalBar {
  height: 8px;
  width: 6px;
  &:nth-child(1) {
    border-radius: 0 0 8px 8px;
  }

  &:nth-child(5) {
    border-radius: 8px 8px 0 0;
  }
}

.medium {
  .rating {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }

  .bar {
    height: 8px;
    width: 24px;
  }
}
