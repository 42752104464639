@import '../../styles/mixins';

.camStill {
    @include aspect-ratio(16,9);
    position: relative;
  }

.videoIcon {
    @include sl-video-play-icon;
}

.playIconContainer {
    z-index: 99;
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
