@import '../../styles/variables';
@import '../../styles/mixins';

.buttons {
  align-items: center;
  display: flex;
  justify-content: right;
  margin-left: 20px;
  text-align: right;
  white-space: nowrap;
}

.arrowButton {
  padding-right: 0;
  height: 44px;
  padding: 6px 0;
  width: 40px;

  &:nth-child(1) {
    padding-left: 6px;
    padding-right: 2px;
  }

  &:nth-child(2) {
    padding-left: 2px;
    padding-right: 6px;
  }

  svg {
    height: 32px;
    width: 32px;

    circle {
      stroke: $grayLight;
    }

    path {
      fill: $textPrimary;
    }
  }

  &:hover,
  &:focus-visible {
    svg {
      circle {
        stroke: $textPrimary;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;

    svg {
      circle {
        stroke: $grayLight;
      }

      path {
        fill: $grayLight;
      }
    }
  }
}

.scrollArrows {
  @media (max-width: $tablet) {
    display: none;
  }
}

.callToActionButton {
  display: inline-flex;
}

.callToActionButtonMobile {
  text-align: center;
}
