@import '../../styles/variables.scss';

.scrollXWrapper {
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  margin-left: -2%;
  margin-right: auto;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none;
  padding-left: 2%;
  scrollbar-width: none;
  white-space: nowrap;
  width: 102%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.linkListLoader {
  backdrop-filter: blur(8px);
  background: rgba(250, 250, 250, 0.08);
  border-radius: 8px;
  margin-bottom: 20px;
}

.linkList {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  text-align: left;
  white-space: nowrap;

  &::after {
    content: '';
    display: inline-block;
    width: 10%;
  }

  :global {
    .MuiButtonBase-root {
      background: rgba(255, 255, 255, 0.08);
      color: $background;

      &:hover,
      &:focus-visible {
        background: rgba(255, 255, 255, 0.2);
      }

      &.Mui-disabled {
        background-color: $background;
        color: $brandSecondary;
      }
    }
  }

  li {
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 0;
  }
}
