@import '../../styles/variables';

$dark-border: 1px solid $brandSecondary;
$light-border: 1px solid $grayLight;

$subregion-filters-height: 32px;

.scrollContainer {
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  margin-left: -2%;
  margin-right: auto;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none;
  padding-left: 2%;
  scrollbar-width: none;
  width: 102%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.regionSelectorContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  overflow-x: visible;
  width: 100%;
}

.regionSelector {
  white-space: nowrap;
  width: 100%;

  &::after {
    content: '';
    display: block;
    padding-right: 10%;
  }

  :global {
    .MuiToggleButton-root {
      gap: 10px;
      height: $subregion-filters-height;
      margin: 0 5px;
      padding: 7px 16px 8px 16px;
      border-radius: 90px;

      .MuiTypography-root {
        color: $textPrimary;
        font-size: 14px;
      }

      &.MuiToggleButtonGroup-grouped {
        border: $light-border;
        margin: 0 5px 0 0;

        &:hover,
        &:focus-visible {
          border: $dark-border;
        }

        &.Mui-selected {
          border: $dark-border;
          background-color: $brandSecondary;
          margin: 0 5px 0 0;

          .MuiTypography-root {
            color: $white;
          }

          &:hover,
          &:focus-visible {
            background-color: $brandSecondary;
            border: $dark-border;

            .MuiTypography-root {
              color: $white;
            }
          }

          + .MuiToggleButtonGroup-grouped.Mui-selected {
            border-left: $dark-border;
          }
        }
      }
    }
  }
}
