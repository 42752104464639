@import '../../styles/_variables.scss';

.map {
  background-color: $grayLight;
  height: 100%;
  margin: 0;
  min-height: 111px;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.mapLarge,
.mapSmall {
  width: 100%;
}

.mapError {
  opacity: 0.5;
}

.mapLoadingSkeleton {
  opacity: 1;
  background-color: unset;
}

.pinContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pin {
  display: block;
}
