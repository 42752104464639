@import '../../../../styles/variables';

.surfCurveChartWrapper {
  width: 100%;
  height: 75px;

  @media (min-width: $tablet) {
    height: 90px;
  }
}

.surfCurveChart {
  width: 100%;
  height: 100%;
}

