@import '../../styles/variables';

.forecasterContentHighlights {
  background-color: $background;
}

.cardText {
  line-height: 1.3em;
  margin: 0.3em 0 1.3em;
}

.paywallLockButton {
  background-color: $brandSecondary;
  height: 44px;
  width: 44px;

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: $white;
    }
  }

  &:hover,
  &:focus-visible {
    background-color: $textPrimary;
  }
}

.paywallLockButtonUnlocked {
  svg {
    path {
      fill: $greenFlash;
    }
  }
}

.loadingHighlights {
  background-color: $background;
  border-radius: 8px;
  display: block;
  min-height: 200px;
}

.star {
  margin-bottom: -1px;
}
