@import '../../styles/variables.scss';

@keyframes fadeInMap {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.mapContainer {
  animation-delay: 200ms;
  animation-fill-mode: backwards;
  animation: fadeInMap 1000ms ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  max-height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;

  @media (min-width: $tablet) {
    height: 600px;
  }

  &::after {
    background-image: linear-gradient(
      to bottom,
      rgba(23, 23, 23, 0.3) 0%,
      rgba(23, 23, 23, 1) 100%
    );
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
