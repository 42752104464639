@import '../../styles/variables.scss';

@keyframes fadeInForecaster {
  0% {
    opacity: 0;
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.wrapper {
  height: 140px;
}

.wrapperAnimate {
  animation: fadeInForecaster 500ms ease-in-out;
}

.forecasterImage {
  display: inline-block;
  height: 72px;
  width: 72px;
  position: relative;

  img {
    border-radius: 50%;
    overflow: hidden;
  }
}

.loading {
    background: rgba(250, 250, 250, 0.08);
}

.title {
  color: $background;
  line-height: 1em;
  margin: 10px 0 20px;
}

.subtitle {
  color: $background;
}

// empty state
.surflineLogo {
  align-items: center;
  background-color: $white;
  border-radius: 50%;
  display: flex;
  height: 72px;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 72px;
}

.waterDrop {
  height: 32px;
  width: 24px;

  path {
    fill: $brandSecondary;
  }
}
