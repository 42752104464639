@import '../../styles/variables.scss';

.wrapper {
  align-items: center;
  display: flex;
  width: 100%;
}

.feedbackIcon {
  background-color: $textPrimary;
  border-radius: 4px;
  height: 32px;
  width: 32px;

  &:last-child {
    margin-left: 12px;
  }

  svg {
    path {
      fill: transparent;
    }
  }
}

.feedbackPositive {
  align-items: center;
  background-color: $greenFlash;
  display: flex;
  justify-content: center;

  svg {
    path {
      fill: $brandSecondary;
    }
  }
}

.feedbackNegative {
  align-items: center;
  background-color: #F48080;
  display: flex;
  justify-content: center;

  svg {
    path {
      fill: $brandSecondary;
    }
  }
}

.message {
  color: $textSecondary;
  line-height: 1.3em;
  line-height: 22px;
  text-align: left;
  width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.paywallLock {
  height: 16px;
  width: 16px;
}

.paywallText {
  color: $background;
}
