@import '../../styles/variables.scss';

.scrolling {
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  margin-left: -18px;
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  white-space: nowrap;
  width: calc(100% + 36px);

  &::-webkit-scrollbar {
    display: none;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  & > li:first-child {
    padding-left: 18px;
  }

  & > li:last-child {
    padding-right: 18px;
  }
}

.day {
  border-radius: 8px;
  height: 70px;
  text-align: center;
  width: 48px;
}

.star {
  margin-top: 6px;
  visibility: hidden;
}

.dayName,
.dayNumber {
  color: #98A2AF;
}

.dayToWatch {
  background-color: #FAFAFA;

  .star {
    visibility: visible;
  }

  .dayName,
  .dayNumber {
    color: #272727;
  }
}
