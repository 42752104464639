@import '../../styles/variables';

$headerHeight: 65px;

.modalContextual {
  height: 100vh;
  height: -webkit-fill-available; // iOS Safari
  height: 100dvh; // Dynamic viewport height - modern browsers
  outline: none;
  overflow-y: auto;
}

.header {
  display: flex;
  flex-direction: column;
  height: $headerHeight;
  justify-content: center;
  position: relative;
}

.headerHint {
  display: none;

  @media(min-width: $desktop) {
    display: block;
    text-align: center;

    span {
      color: $white;
    }
  }
}

.headerClose {
  position: absolute;
  right: 20px;
  top: 15px;
}

.content {
  border-radius: 20px 20px 0 0;
  min-height: calc(100vh - $headerHeight);
  overflow: hidden;
  position: relative;
}
